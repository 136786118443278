import React from "react";
import { useTranslation } from 'react-i18next';

function Dashboard() {
    const { t } = useTranslation();
//   const [activeScooters, setActiveScooters] = useState(0);
//   const [availableScooters, setAvailableScooters] = useState(0);
//   const [brokenScooters, setBrokenScooters] = useState(0);
//   const [customers, setCustomers] = useState(0);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">{t('Dashboard')}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-xl font-semibold mb-2">{t('Active Rented Scooters')}</h3>
          <p className="text-4xl font-bold text-indigo-600">0</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-xl font-semibold mb-2">{t('Available Scooters')}</h3>
          <p className="text-4xl font-bold text-green-500">0</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-xl font-semibold mb-2">{t('Broken Scooters')}</h3>
          <p className="text-4xl font-bold text-red-500">0</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-xl font-semibold mb-2">{t('Registered Customers')}</h3>
          <p className="text-4xl font-bold text-indigo-600">0</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-xl font-semibold mb-2">{t('Old/Sold Scooters')}</h3>
          <p className="text-4xl font-bold text-yellow-600">0</p>
        </div>
        
      </div>
    </div>
  );
}

export default Dashboard;
